/* eslint-disable no-console */
import $ from "cash-dom";
import { onImageLoaded } from "./utils";

// eslint-disable-next-line func-names
$.fn.exists = function () {
  return this.length > 0;
};

// eslint-disable-next-line func-names
$.fn.loadingIcon = function (options) {
  const defaults = {
    iconClass: "loading-icon",
    loadingClass: "loading",
    loadedClass: "loaded",
    initializedClass: "with-loading-icon",
    dontHideIfLoaded: false,
    // eslint-disable-next-line no-unused-vars
    onImagesLoaded: (element) => {},
  };
  const settings = $.extend({}, defaults, options);

  // eslint-disable-next-line func-names
  return this.each(function () {
    const element = $(this);
    if (element.hasClass(settings.initializedClass)) return;

    if (!element.find(`.${settings.iconClass}`).length) {
      const div = $(`<div class="${settings.iconClass}"></div>`);
      div.appendTo(element);
    }
    element.addClass(settings.loadingClass);
    element.addClass(settings.initializedClass);
    element.removeClass(settings.loadedClass);

    if (!settings.dontHideIfLoaded) {
      onImageLoaded(element, () => {
        element
          .removeClass(settings.loadingClass)
          .addClass(settings.loadedClass);
        settings.onImagesLoaded(element);
      });
    }
  });
};