// xxx can onscroll be passive? document.addEventListener('touchstart', onTouchStart, {passive: true});

/* eslint-disable no-console */
import $ from "cash-dom";

import "./helpers/cash-extensions";

import { getScript } from "./helpers/utils";

import { runPjax } from "./modules/pjax";

import * as map from "./modules/map";
import * as plyrController from "./modules/plyr-controller";
import * as slideshows from "./modules/slideshows";
import * as fullscreen from "./modules/fullscreen";
import * as hero from "./modules/hero";
import * as pageMenu from "./modules/pagemenu";

window.$ = $;

// kick off the polyfill!

let currentOrientation = "";
let lbhSet = false;

const app = {
  isIOS: false,
  isAndroid: false,
  isTouch: false,
  isChrome: false,
  isSafari: false,
  isTablet: false,
  isDesktop: false,

  adminBarCorrector: 0,
  win: $(window),
  html: $("html"),
  body: false,
  wW: 0,
  wH: 0,
  wH100vh: 0,
  wH100percent: 0,
  lbh: 0,
  sbw: 0,
  sT: 0,
  pageContainer: false,
  pageContent: false,
  classContainer: false,
  menuBreakPoint: window.WSM.menuBreakPoint ?? 1020,
  hideOnScrollBreakPoint: 740,

  url_referrer: "",
  url_current: "",
  previousScrollTopOnPageChange: 0,
  scrollTopOnPageChange: 0,
  is_back_navigation: false,

  doneLoadingIcons: false,
  pageScrolledUp: false,

  eventScope: ".main",
  scrollEventHandler: "scroll",
  scrollbarWidth: 0,
  resizeDeferralTimer: 0,
  loadinIconImageSelector: ".force-lazy-load",
  // NOTE: you might need to bebind events if you add new elements. app.pjax.refresh()
  // also you want to ensure that any new elements (closnes) do not have the attribute data-pjax-state as otherwise pjax will not bind.
  pjax: runPjax({
    elements: "a[data-pjax]",
    history: true,
    scrollTo: false,
    scrollResToration: false,
    cacheBust: false,
    debug: false,
    analytics: false,
    selectors: ["title", "meta[name=description]", ".class-container"],
  }),
  modules: {
    plyrController,
  },
};
window.app = app;

app.scrollEventHandler = `scroll${app.eventScope}`;

let passiveIfSupported = false;
try {
  window.addEventListener(
    "test",
    null,
    Object.defineProperty({}, "passive", {
      get: () => {
        passiveIfSupported = { passive: false };
        return passiveIfSupported;
      },
    })
  );
  // eslint-disable-next-line no-empty
} catch (err) {}

let onScrollTicking = false;
const onScroll = () => {
  app.sT = Math.floor(Math.max(0, window.pageYOffset));

  if (!onScrollTicking) {
    window.requestAnimationFrame(() => {
      pageMenu.onScroll();
      map.onScroll();
      hero.onScroll();

      onScrollTicking = false;
    });

    onScrollTicking = true;
  }
};

const onResize = () => {
  app.wW = window.innerWidth;
  app.wH = window.innerHeight;

  if ($("#wpadminbar").length)
    app.adminBarCorrector = $("#wpadminbar").height();

  if (!app.doneLoadingIcons) {
    app.doneLoadingIcons = true;
    $(app.loadinIconImageSelector).loadingIcon();
  }

  const orientation = app.wW > app.wH ? "landscape" : "portrait";

  if (orientation !== currentOrientation) {
    try {
      currentOrientation = orientation;
      const outer = document.createElement("div");
      document.body.appendChild(outer);

      outer.style.visibility = "hidden";
      outer.style.width = "100px";
      outer.style.height = "100vh";
      outer.style.overflow = "scroll";
      outer.style.position = "fixed";
      outer.style.top = "0";
      const widthNoScroll = outer.offsetWidth;

      const inner = document.createElement("div");
      inner.style.width = "100%";
      outer.appendChild(inner);
      const widthScroll = inner.offsetWidth;

      const h100vh = Math.max(app.wH, outer.offsetHeight);

      const sbw = widthNoScroll - widthScroll;

      outer.style.height = "100%";

      const h100percent = Math.min(app.wH, outer.offsetHeight);
      const lbh = h100vh - h100percent;

      document.documentElement.style.setProperty(
        "--sbw",
        `${sbw.toFixed(0)}px`
      );

      app.sbw = sbw;
      app.lbh = lbh;
      app.wH100vh = h100vh;
      app.wH100percent = h100percent;

      document.body.removeChild(outer);
    } catch (e) {
      // do nothing ...
    }
  }

  document.documentElement.style.setProperty(
    "--lbh",
    `${(app.wH100vh - app.wH).toFixed(0)}px`
  );

  if (!lbhSet) {
    lbhSet = true;
    document.documentElement.style.setProperty(
      "--initial-lbh",
      `${(app.wH100vh - app.wH).toFixed(0)}px`
    );
  }

  pageMenu.onResize();
  plyrController.onResize();
  map.onResize();

  app.win.trigger("app:resized");
};
const onResizeDeferred = () => {
  clearTimeout(app.resizeDeferralTimer);
  app.resizeDeferralTimer = setTimeout(onResize, 100);
};
app.win.on("resize orientationchange", onResizeDeferred);

const onReadyOneTime = () => {
  const uA = navigator.userAgent.toLowerCase();

  app.isAndroid = /android/.test(uA);
  app.isChrome = /chrome/.test(uA);
  app.isSafari = !app.isChrome && /safari|applewebkit|ipad|iphone/.test(uA);

  app.isTouch = (navigator.maxTouchPoints || 0) > 0 || "ontouchstart" in window;

  app.isIOS = app.isTouch && app.isSafari;

  app.isTablet = app.isTouch && (app.isIOS || app.isAndroid);
  app.isDesktop = !app.isIOS && !app.isAndroid;

  app.wW = app.win.width();
  app.wH = app.win.height();

  app.url_referrer = document.referrer;
  app.url_current = document.location.href;

  if (app.isTouch)
    app.scrollEventHandler +=
      " touchstart touchmove touchend orientationchange";

  app.body = $("body");
  app.pageContainer = $(".page-container");
  app.pageContent = $(".page-content");
  app.classContainer = $(".class-container");

  $(document).on("click", ".disabled", (event) => {
    event.preventDefault();
    return false;
  });

  pageMenu.onReadyOneTime();
  plyrController.onReadyOneTime(
    window?.WSM?.plyrJsUrl,
    window?.WSM?.plyrCssUrl,
    window?.WSM?.plyrBlankUrl
  );

  map.onReadyOneTime();
  slideshows.onReadyOneTime(window?.WSM?.swiperJsUrl);
  fullscreen.onReadyOneTime(window?.WSM?.swiperJsUrl);

  app.scrollEventHandler
    .split(" ")
    .forEach((type) =>
      window.addEventListener(type, onScroll, passiveIfSupported)
    );

  app.win.on(app.scrollEventHandler, onScroll);
  onResize();

  if (app.sbw > 0) {
    document.body.classList.add("has-scrollbars");
  }

  $(document).on("click", ".soft-scroll", (event) => {
    if (event.target) {
      const jumpTarget = $($(event.target).attr("href"));

      if (jumpTarget.exists()) {
        event.preventDefault();
        const top =
          jumpTarget.offset().top - $(".page-header").outerHeight() - 20;

        window.scrollTo({ top, left: 0, behavior: "smooth" });

        setTimeout(() => {
          document.location.replace($(event.target).attr("href"));
        }, 300);
      }
    }
  });

  let tabbedTimeout = 0;
  const tabPressed = (e) => {
    if (e.key === "Tab" || e.keyCode === 9) {
      if (tabbedTimeout) clearTimeout(tabbedTimeout);

      tabbedTimeout = setTimeout(() => {
        document.body.classList.remove("tabbed");
      }, 2500);

      document.body.classList.add("tabbed");
    }
  };
  document.body.addEventListener("keyup", tabPressed);

  app.win.trigger("app:ready-one-time");

  if (
    !("scrollBehavior" in document.documentElement.style) &&
    window?.WSM?.smoothscrollJsUrl
  ) {
    getScript(window?.WSM?.smoothscrollJsUrl);
  }
};
$(onReadyOneTime);

const onReady = () => {
  app.wW = app.win.width();
  app.wH = app.win.height();

  if ($("#wpadminbar").length) {
    app.adminBarCorrector = $("#wpadminbar").outerHeight();
  } else {
    app.adminBarCorrector = 0;
  }

  app.body = $("body");
  app.pageContainer = $(".page-container");
  app.pageContent = $(".page-content");
  app.classContainer = $(".class-container");

  if (app.classContainer.hasClass("has-admin-bar") > 0) {
    document.body.classList.add("admin-bar");
  } else {
    document.body.classList.remove("admin-bar");
  }

  $(".back-to-referal")
    .each((index, element) => {
      if (
        app.url_referrer !== "" &&
        app.url_referrer !== app.url_current &&
        (app.url_referrer.indexOf(window.WSM.home_url) > -1 ||
          app.url_referrer.indexOf("localhost") > -1) &&
        app.url_referrer.indexOf("wp-admin") === -1
      ) {
        $(element).attr("href", app.url_referrer);
      }
    })
    .on("click", () => {
      app.is_back_navigation = true;
    });

  $(window).on("popstate", () => {
    app.is_back_navigation = true;
  });

  if (app.is_back_navigation) {
    window.scrollTo({
      top: app.previousScrollTopOnPageChange,
      left: 0,
      behavior: "smooth",
    });
    app.is_back_navigation = false;
  } else {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }

  app.pageScrolledUp = false;

  $("a:not([data-pjax])").each((index, element) => {
    const link = $(element);
    const url = link.attr("href");
    if (url.indexOf("#") === 0 && !link.hasClass("skiplink"))
      link.addClass("soft-scroll");

    if (
      (url.indexOf(window.WSM.domain) > -1 &&
        url.indexOf("superweston/download") === -1) ||
      (url.indexOf("/") === 0 &&
        url.indexOf("//") !== 0 &&
        !link.hasClass("skiplink"))
    ) {
      link.attr("data-pjax", true);
    }
  });
  app.pjax.refresh();

  map.onReady();
  plyrController.onReady();
  fullscreen.onReady();
  slideshows.onReady();
  hero.onReady();
  pageMenu.onReady();

  onResize();

  // ensure that a resize is triggerd once all fonts are loaded
  if ("fonts" in document) {
    document.fonts.ready.then(() => {
      onResize();
    });
  }

  app.win.trigger("app:ready");
};
$(onReady);

$(document)
  .off(`pjax:send${app.eventScope}`)
  .on(`pjax:send${app.eventScope}`, (options) => {
    app.previousScrollTopOnPageChange = app.scrollTopOnPageChange;
    app.scrollTopOnPageChange = window.pageYOffset;

    if (app.url_current !== options.url) {
      $("body").addClass("page-loading");
      $(".loadingbar").addClass("loading");
      document.body.setAttribute("tabindex", "-1");
    }

    const stopAutoPlay = (i, v) => {
      v.pause();
      v.removeAttribute("autoplay");
    };
    $("video[autoplay]").each(stopAutoPlay);
    plyrController.pjaxSend();
    pageMenu.pjaxSend();
    map.pjaxSend();
    fullscreen.pjaxSend();
    slideshows.pjaxSend();
    hero.pjaxSend();

    $("body").removeClass("crop");

    $(window).trigger("app:pjax-send");
  });

/*
  BEFOREREPLACE and END are the only events being triggered by page load and hiapp.sTory pop
  END is also called if requeapp.sT fails.
  */
$(document)
  .off(`pjax:success${app.eventScope}`)
  .on(`pjax:success${app.eventScope}`, () => {
    document.body.focus();

    $("#aria-announcer").text(`Navigated to ${document.title.split("|")[0]}`);

    app.url_referrer = app.url_current;
    app.url_current = document.location.href;

    app.doneLoadingIcons = false;

    $(".loadingbar").removeClass("loading");
    $("body").removeClass("page-loading");

    pageMenu.unlock();
    fullscreen.unlock();

    document.body.removeAttribute("tabindex");
    document.body.classList.remove("tabbed");
    document.body.focus();

    // xxx if (typeof _gaq !== 'undefined')
    //   _gaq.push(['_trackPageview', app.url_current]);

    // if (typeof ga !== "undefined"){
    //   ga('send', 'pageview', {
    //     'page': app.url_current,
    //     'title': document.title
    //   });
    // }
    //
    // if (typeof gtag !== "undefined"){
    //   gtag('config', 'xxx_GA_TRACKING_ID', {
    //     'page_title' : document.title,
    //     'page_location': app.url_current
    //   });
    // }

    $(window).trigger("app:pjax-success");
    onReady();
  });
