/* eslint-disable no-console */
import $ from "cash-dom";
import { getScript } from "../helpers/utils";

let isInit = false;

const assets = {
  js: {
    loading: false,
    loaded: false,
  },

  css: {
    loading: false,
    loaded: false,
  },
};

const init = (runOnReady) => {
  if (isInit) return;

  isInit = true;

  if (typeof window.mapController !== "undefined") {
    window.mapController.onInit("map");
    if (runOnReady) window.mapController.onReady();
  }
};

const checkAssets = () => {
  if (
    typeof window.mapboxgl !== "undefined" &&
    typeof window.mapController !== "undefined"
  ) {
    assets.js.loaded = true;
  }

  if (!assets.js.loaded && !assets.js.loading) {
    assets.js.loading = true;
    if (
      typeof window.mapboxgl === "undefined" &&
      typeof window.mapController === "undefined"
    ) {
      getScript(window.WSM.mapboxJsUrl, () => {
        getScript(window.WSM.mapJsUrl, () => {
          assets.js.loaded = true;
          init(true);
        });
      });
    } else if (typeof window.mapboxgl === "undefined") {
      getScript(window.WSM.mapJsUrl, () => {
        assets.js.loaded = true;
        init(true);
      });
    } else {
      getScript(window.WSM.mapboxJsUrl, () => {
        assets.js.loaded = true;
        init(true);
      });
    }
  } else if (assets.js.loaded) {
    init(false);
  }

  if (!assets.css.loaded) {
    assets.css.loaded = true;

    if (!$("#mapbox-css").exists()) {
      const link = $("<link />");
      link
        .attr({
          id: "mapbox-css",
          rel: "stylesheet",
          type: "text/css",
          href: window.WSM.mapboxCssUrl,
        })
        .appendTo("head");
    }

    if (!$("#map-css").exists()) {
      const link = $("<link />");
      link
        .attr({
          id: "map-css",
          rel: "stylesheet",
          type: "text/css",
          href: window.WSM.mapCssUrl,
        })
        .appendTo("head");
    }
  }
  return true;
};

// xxx neeeded?
export const onReadyOneTime = () => {
  if (!window.app.classContainer.hasClass("has-map")) return;

  checkAssets();
};

export const onScroll = () => {
  if (
    isInit &&
    typeof window.mapboxgl !== "undefined" &&
    typeof window.mapController !== "undefined"
  ) {
    window.mapController.onScroll();
  }
};

export const onResize = () => {
  if (
    isInit &&
    typeof window.mapboxgl !== "undefined" &&
    typeof window.mapController !== "undefined"
  ) {
    window.mapController.onResize();
  }
};

export const onReady = () => {
  if (!window.app.classContainer.hasClass("has-map-layout") && !window.app.classContainer.find('.block.map').exists()) {
    const container = $(".map-container");
    if (container.exists()) {
      container.data("ui", 0);
      container.data("back", 0);
    }
    return;
  }

  checkAssets();

  if (
    isInit &&
    typeof window.mapboxgl !== "undefined" &&
    typeof window.mapController !== "undefined"
  ) {
    window.mapController.onReady();
  }
};

// if (isOnReady && typeof window.mapController !== "undefined") {
//       window.mapController.onReady();
//     }

// export const onResize = () => {
//   if (!isInit) init();
//   if (!inScope) return;
//   console.log("onResize");
// };

// export const onScroll = () => {
//   if (!isInit) init();

//   if (!inScope) return;

//   console.log("onScroll");
// };

// xxx clean up.
// export const reset = () => {
//   inScope = false;
//   isInit = false;
// };

export const pjaxSend = () => {
  if (
    isInit &&
    typeof window.mapboxgl !== "undefined" &&
    typeof window.mapController !== "undefined"
  ) {
    window.mapController.onPjaxSend();
  }
};
