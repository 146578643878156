/* eslint-disable no-console */
import $ from "cash-dom";

const selectors = {
  header: ".page-header.menu-reveal",
  button: ".page-header.menu-reveal .menu-toggle",
  screen: ".page-header.menu-reveal .screen",
  nav: ".page-header.menu-reveal .nav.main",
};
const timings = {
  open: 500,
  close: 500,
};
let lastScrollTop = 0;
const cropBody = true;
const hideOnScrollOffset = {
  minST: 0,
  up: 75,
  down: 150,
};

// let nav = null;
let state = "";
let button = false;
let container = false;
let timer = 0;
let busy = false;
let observeOnResize = false;
let menuOpen = false;
let hideOnScroll = true;
let hideOnScrollObserving = false;
let hideOnScrollBreakPoint = 777;
let hideOnScrollHasClass = false;
let hideOnScrollCurrentScroll = 0;

export const getState = () => state;

const trapFocusKeyDownEventHandler = (e) => {
  const focusableEls = container.find(
    // 'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
    "a[href]:not([disabled]), button:not([disabled])"
  );
  const firstFocusableEl = focusableEls[0];
  const lastFocusableEl = focusableEls[focusableEls.length - 1];
  const KEYCODE_TAB = 9;

  const isTabPressed = e.key === "Tab" || e.keyCode === KEYCODE_TAB;

  if (!isTabPressed) {
    return;
  }

  if (e.shiftKey) {
    if (document.activeElement === firstFocusableEl) {
      lastFocusableEl.focus();
      e.preventDefault();
    }
  } else if (document.activeElement === lastFocusableEl) {
    firstFocusableEl.focus();
    e.preventDefault();
  }
};

export const lock = () => {
  busy = true;
};

export const unlock = () => {
  busy = false;

  if (timer > 0) {
    clearTimeout(timer);
    timer = 0;
  }
};

export const close = () => {
  if (busy) return;

  if (!button) return;

  if (
    !menuOpen &&
    !container.hasClass("open-menu") &&
    !button.find(".hamburger").hasClass("is-active")
  )
    return;

  if (timer > 0) {
    clearTimeout(timer);
    timer = 0;
  }

  lock();

  window.app.html.attr("data-overlay", "closed");

  const body = $("body");
  if (cropBody && body.hasClass("crop")) {
    if (window.app.scrollbarWidth > 0) {
      body.css("padding-right", "");
      $(selectors.header).css("width", "");
    }
    body.removeClass("crop");

    window.scrollTo({
      left: 0,
      top: lastScrollTop,
    });
  }

  container.removeClass("open-menu").addClass("close-menu");
  container.off("keydown");

  button.find(".hamburger").removeClass("is-active");

  button.attr("aria-expanded", false);
  button.attr("aria-label", "Open menu");

  timer = setTimeout(() => {
    unlock();
    container.removeClass("close-menu");
    menuOpen = false;
  }, timings.close);
};

export const open = (event) => {
  if (typeof event !== "undefined") event.preventDefault();

  if (busy) return;

  if (!button) return;

  // xxx disabled on Larger Screens?
  if (window.app.wW >= window.app.menuBreakPoint) return;

  if (timer > 0) {
    clearTimeout(timer);
    timer = 0;
  }

  if (container.hasClass("open-menu")) {
    close();
  } else {
    lock();

    window.app.html.attr("data-overlay", "open");
    button.find(".hamburger").addClass("is-active");
    button.attr("aria-expanded", true);
    button.attr("aria-label", "Close menu");
    container.removeClass("close-menu").addClass("open-menu");
    menuOpen = true;
    button.removeAttr("hidden");

    container.off("keydown").on("keydown", trapFocusKeyDownEventHandler);
    // xxx nav.find('.sidebar').removeAttr('hidden')

    timer = setTimeout(() => {
      unlock();
      if (cropBody) {
        lastScrollTop = window.pageYOffset;
        if (window.app.scrollbarWidth > 0) {
          $("body").css("padding-right", window.app.scrollbarWidth);
          $(selectors.header).css(
            "width",
            window.app.wW - window.app.scrollbarWidth
          );
        }
        $("body").addClass("crop");
      }
    }, timings.open);
  }
};

export const onReady = () => {
  hideOnScrollBreakPoint = window.app.hideOnScrollBreakPoint;

  container = $(selectors.header);
  if (container.length) {
    // carefull this is in LoadOnce!
    if (container.hasClass("menu-hide-on-scroll")) {
      hideOnScroll = true;
    }

    button = $(selectors.button);
    button.removeAttr("hidden");

    // nav = $(selectors.nav);
    // xxx nav.find('.sidebar').attr('hidden', true)
  }
};

export const onReadyOneTime = () => {
  hideOnScrollBreakPoint = window.app.hideOnScrollBreakPoint;

  container = $(selectors.header);
  if (container.length) {
    $(document).on("click", selectors.button, open);
    $(document).on("click", selectors.screen, close);

    // carefull this is in LoadOnce!
    if (container.hasClass("menu-hide-on-scroll")) {
      hideOnScroll = true;
    }
  }
};

export const onScroll = () => {
  if (hideOnScroll && !menuOpen) {
    if (window.app.wW >= hideOnScrollBreakPoint) {
      if (hideOnScrollObserving) {
        hideOnScrollObserving = false;
        if (hideOnScrollHasClass) {
          window.app.body.data('menu-tuck-up', 0);
          hideOnScrollHasClass = false;
        }
      }
    } else {
      hideOnScrollObserving = true;
      if (window.app.sT <= hideOnScrollOffset.minST) {
        hideOnScrollCurrentScroll = window.app.sT;
        if (hideOnScrollHasClass) {
          window.app.body.data('menu-tuck-up', 0);
          hideOnScrollHasClass = false;
        }
      } else {
        const change = window.app.sT - hideOnScrollCurrentScroll;
        if (change >= hideOnScrollOffset.down) {
          hideOnScrollCurrentScroll = window.app.sT;
          if (!hideOnScrollHasClass) {
            window.app.body.data('menu-tuck-up', 1);
            hideOnScrollHasClass = true;
          }
        }
        if (change <= -1 * hideOnScrollOffset.up) {
          hideOnScrollCurrentScroll = window.app.sT;
          if (hideOnScrollHasClass) {
            window.app.body.data('menu-tuck-up', 0);
            hideOnScrollHasClass = false;
          }
        }
      }
    }
  }
};

export const onResize = () => {
  if (!window.app.sTate) {
    if (window.app.wW < window.app.menuBreakPoint) {
      state = "mobile";
    } else {
      state = "desktop";
    }
  } else if (window.app.wW < window.app.menuBreakPoint) {
    if (window.app.sTate === "desktop") {
      close();
      state = "mobile";
    }
  } else if (window.app.sTate === "mobile") {
    close();
    state = "desktop";
  }

  if (window.app.wW >= window.app.menuBreakPoint) {
    if (observeOnResize) {
      close();
      observeOnResize = false;

      container.removeClass("open-menu close-menu");
    }
  } else {
    observeOnResize = true;
  }

  onScroll();  
};

export const pjaxSend = () => {
  close();

  if (cropBody) {
    const body = $("body");
    if (window.app.scrollbarWidth > 0) {
      body.css("padding-right", "");
      $(selectors.header).css("width", "");
    }
    body.removeClass("crop");
  }

  window.app.body.data('menu-tuck-up', 0);
};
