/* eslint-disable no-console */

import $ from "cash-dom";
import { getScript } from "../helpers/utils";

const assets = {
  js: {
    url: false,
    loading: false,
    loaded: false,
  },
};

let isInit = false;
let swipers = [];

const getValue = (node, key, fallback) =>
  node.getAttribute(key) && !Number.isNaN(node.getAttribute(key))
    ? parseInt(node.getAttribute(key), 10)
    : fallback;

const initSlideshows = async () => {
  $(".swiper-container").each((index, element) => {
    element.classList.add(`swiper-${index}`);

    const swiper = new window.Swiper(`.swiper-${index}`, {
      // const swiper = createSwiper(`.swiper-${index}`, {
      effect: element.getAttribute("data-effect") ?? "slide",
      speed: getValue(element, "data-speed", 500),
      loop: element.getAttribute("data-loop")
        ? element.getAttribute("data-loop") !== "false"
        : false,
      autoplay:
        element.getAttribute("data-autoplay") &&
        element.getAttribute("data-autoplay") !== "false"
          ? {
              delay: getValue(element, "data-delay", 4000),
            }
          : false,
      edgeSwipeDetection: true,
      edgeSwipeThreshold: 40,
      grabCursor: true,
      slidesPerView: "auto", // not if locked.
      navigation: {
        nextEl: `.swiper-${index} .swiper-button-next`,
        prevEl: `.swiper-${index} .swiper-button-prev`,
      },
      mousewheel: {
        forceToAxis: true,
        sensitivity: 1,
        thresholdDelta: 60,
      },
    });

    $(element)
      .on("pointerenter", () => {
        element.classList.add(`pointer-over`);
      })
      .on("pointerleave", () => {
        element.classList.remove(`pointer-over`);
      });

    swipers.push(swiper);
  });
};

export const init = () => {
  if (isInit) return;

  isInit = true;

  if (!$(".swiper").exists()) return;

  if (typeof window.Swiper !== "undefined") {
    assets.js.loaded = true;
  }

  if (!assets.js.loaded && !assets.js.loading) {
    assets.js.loading = true;
    getScript(assets.js.url, () => {
      assets.js.loaded = true;
      initSlideshows();
    });
  } else if (assets.js.loaded) {
    initSlideshows();
  }
};

export const onReady = () => {
  if (isInit) return;

  init();
};

export const onReadyOneTime = (jsUrl) => {
  assets.js.url = jsUrl;
  onReady();
};

export const reset = () => {
  isInit = false;

  Object.keys(swipers).forEach((i) => {
    swipers[i].destroy(true, true);
  });
  swipers = [];
};

export const pjaxSend = () => {
  reset();
};
