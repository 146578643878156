import $ from "cash-dom";

let inScope = false;
let isInit = false;
let transparentNav = false;

const init = () => {
  isInit = true;

  inScope = $(".hero").length > 0;
};

export const onReady = () => {
  if (!isInit) init();
};

export const onScroll = () => {
  if (!inScope) return;
  
  if (window.pageYOffset < window.app.wH * 0.85) {
    if (!transparentNav) {
      window.app.classContainer
        .addClass("menu-transparent")
        .removeClass("show-big-nav");
      transparentNav = true;

      $(".hero .video-player").each((index, element) => {
        window.app.modules.plyrController.play($(element).attr("id"));
      });
    }
  } else if (transparentNav) {
    window.app.classContainer.removeClass("menu-transparent");
    transparentNav = false;
    $(".hero .video-player").each((index, element) => {
      window.app.modules.plyrController.pause($(element).attr("id"));
    });
  }
};

export const onResize = () => {
  if (!inScope) return;

  onScroll();
};


export const reset = () => {
  inScope = false;
  isInit = false;
  transparentNav = false;
  window.app.classContainer.removeClass("menu-transparent");
};

export const pjaxSend = () => {
  reset();
};
