/* eslint-disable no-console */
import $ from "cash-dom";

import { getScript, getJSONData } from "../helpers/utils";

if (typeof Object.entries === "undefined") {
  getScript(
    "https://cdn.polyfill.io/v3/polyfill.min.js?features=es6,Array.prototype.includes,CustomEvent,Object.entries,Object.values,URL,URLSearchParams,Math.trunc&flags=gated"
  );
}

const assets = {
  js: {
    url: false,
    loading: false,
    loaded: false,
  },
  css: {
    url: false,
    loaded: false,
  },
  blankVideo: {
    url: false,
  },
};
let isInit = false;
let plyrs = {};

const fitSrc = (sources, activeKey, containerWidth, containerHeight) => {
  const result = {
    noSource: {
      key: "",
      url: null,
    },
    best: {
      key: "",
      url: null,
    },
    biggest: {
      key: "",
      url: null,
    },
  };

  if (Object.keys(sources).length) {
    Object.keys(sources).reduce((carry, key) => {
      carry.biggest.key = key;
      carry.biggest.url = sources[key].url;

      if (!carry.best.key) {
        if (containerWidth > containerHeight) {
          if (sources[key].width > containerWidth) {
            carry.best.key = key;
            carry.best.url = sources[key].url;
          }
        } else if (sources[key].height > containerHeight) {
          carry.best.key = key;
          carry.best.url = sources[key].url;
        }
      }

      return carry;
    }, result);
  }

  if (result.best.key === activeKey && activeKey !== "") return result.noSource;

  if (result.best.url && result.best.key !== activeKey) {
    return result.best;
  }

  if (result.biggest.url && result.biggest.key !== activeKey) {
    return result.biggest;
  }

  return result.noSource;
};

const getSources = (json) => {
  let sources = [];

  if (typeof json.files !== "undefined") {
    sources = json.files;
  } else {
    sources = json;
  }

  if (sources.length > 0) {
    const compare = (a, b) => {
      if (a.width < b.width) return -1;
      if (a.width > b.width) return 1;
      return 0;
    };
    sources.sort(compare);
  }

  return sources;
};

const getPosterURL = (json, width, height) => {
  if (window.loadRetinaImages) {
    width *= 2;
    height *= 2;
  }

  const sizes = json?.poster?.sizes ?? {};

  if (Object.keys(sizes).length > 0) {
    const urls = Object.keys(sizes).reduce(
      (carry, size) => {
        if (size.indexOf("-width") > -1 || size.indexOf("-height") > -1)
          return carry;

        if (
          sizes[`${size}-width`].width > width &&
          sizes[`${size}-width`].height > height
        ) {
          carry.fallback = sizes[size];
          carry.fallback = sizes[size];
        } else {
          carry.fallback = sizes[size];
        }
        return carry;
      },
      {
        best: null,
        fallback: null,
      }
    );

    return urls?.best ?? urls.fallback ?? null;
  }

  return null;
};

export const unload = (id) => {
  if (!isInit) return;

  if (typeof plyrs[id] !== "undefined") {
    plyrs[id].plyr.destroy();
    delete plyrs[id];
  }
};

export const unloadAll = () => {
  if (!isInit) return;

  Object.keys(plyrs).forEach((pID) => {
    plyrs[pID].plyr.destroy();
    delete plyrs[pID];
  });

  plyrs = {};
};

export const clear = (id) => {
  if (!isInit) return;

  isInit = false;

  Object.keys(plyrs).forEach((pID) => {
    if (pID === id) {
      plyrs[id].plyr.destroy();
      delete plyrs[pID];
    }
  });

  plyrs = {};
};

export const play = (id) => {
  if (typeof plyrs[id] !== "undefined") {
    try {
      const playPromise = plyrs[id].plyr.play();

      if (playPromise !== undefined) {
        playPromise.catch((err) => {
          console.error(`PlyrController`,err);
        });
      }
    } catch (err) {
      console.error(`PlyrController`, err);
    }
  }
};

export const pauseOther = (id) => {
  Object.keys(plyrs).forEach((pID) => {
    if (pID !== id) {
      plyrs[pID].plyr.pause();
    }
  });
};

export const pause = (id) => {
  if (typeof plyrs[id] !== "undefined") {
    plyrs[id].plyr.pause();
  }
};

export const pauseAll = () => {
  Object.keys(plyrs).forEach((pID) => {
    plyrs[pID].plyr.pause();
  });
};

export const stop = (id) => {
  if (typeof plyrs[id] !== "undefined") {
    plyrs[id].plyr.stop();
  }
};

export const initPlayer = (container, id) => {
  if (container.hasClass("initialized")) return;

  container.addClass("initialized");

  if (container.exists() && typeof plyrs[id] === "undefined") {
    const json = getJSONData(`${id}_json`);
    let sources;

    if (json) {
      if (typeof sources === "undefined") sources = getSources(json);

      if (Object.keys(sources ?? {}).length > 0) {
        plyrs[id] = {
          activeKey: "",
          sources,
          json,
        };

        const poster = getPosterURL(
          json,
          container.width(),
          container.height()
        );

        const videoSrc = fitSrc(
          plyrs[id].sources,
          plyrs[id].activeKey,
          container.width(),
          container.height()
        );

        plyrs[id].plyr = new window.Plyr(container.find("video").get(0), {
          debug: false,
          ...(container.hasClass("with-controls")
            ? {
                controls: [
                  "play",
                  "duration",
                  "progress",
                  "current-time",
                  "mute",
                  "volume",
                  "captions",
                  "fullscreen",
                ],
              }
            : { controls: false, fullscreen: { enabled: false } }),
          muted: true, // container.hasClass("autoplay"),
          iconUrl: "",
          loadSprite: false,
          // ratio: '16:9',
          keyboard: {
            global: false,
          },
          displayDuration: true,
          tooltips: {
            controls: true,
          },
          ...(container.hasClass("with-controls")
            ? {
                fullscreen: {
                  enabled: true,
                  iosNative: true,
                },
                clickToPlay: true,
              }
            : { fullscreen: { enabled: false }, clickToPlay: false }),

          blankVideo: assets.blankVideo.url,
        });

        if (container.hasClass("autoplay")) {
          plyrs[id].plyr.muted = true;
        }

        plyrs[id].plyr.on("enterfullscreen", () => {
          if (window.app.isSafari && !window.app.isTablet) {
            $("body").addClass("fullscreen-video");
          }
        });

        plyrs[id].plyr.on("exitfullscreen", () => {
          if (window.app.isSafari && !window.app.isTablet) {
            $("body").removeClass("fullscreen-video");
          }
        });

        if (videoSrc.url) {
          let plyrSources = [];
          plyrs[id].activeKey = videoSrc.key;

          plyrSources = [
            {
              src: videoSrc.url,
              type: "video/mp4",
            },
          ];

          plyrs[id].plyr.source = {
            type: "video",
            sources: plyrSources,
            poster,
            ...(json?.captions
              ? {
                  tracks: [
                    {
                      kind: "captions",
                      label: "English",
                      srclang: "en",
                      src: json?.captions,
                      default: true,
                    },
                  ],
                }
              : {}),
          };
        }
      }
    }
  }

  if (typeof plyrs[id] === "undefined") {
    console.error(`PlyrController: Error creating player: ${id}`);
    return;
  }

  plyrs[id].plyr.currentTime = 0;

  plyrs[id].plyr.on("ready", (event) => {
    const instance = event.detail.plyr;
    $(instance.elements.container).addClass(
      "plyr-has-not-been-played plyr-initialized"
    );
  });

  // if video has no audio track hide ugly gap
  plyrs[id].plyr.on("canplay loadeddata", (event) => {
    const instance = event.detail.plyr;

    // force chrome and other browser to show mute button.
    if (window.app.isDesktop) {
      $(instance.elements.container).removeClass("plyr-has-no-volume-control");
      $(instance.elements.container)
        .find(
          ".plyr__controls .plyr__volume, .plyr__controls .plyr__volume [data-plyr=mute]"
        )
        .removeAttr("hidden");
    }

    container.removeClass("loading").addClass("loaded");

    setTimeout(() => {
      container.removeClass("with-loading-icon").removeClass("loaded");
    }, 400);

    if (container.hasClass("autoplay")) {
      // plyrs[id].plyr.muted = true;
      play(id);
    }
  });

  plyrs[id].plyr.on("play", (event) => {
    const instance = event.detail.plyr;
    $(instance.elements.container)
      .removeClass("plyr-has-ended plyr-has-not-been-played")
      .addClass("plyr-has-been-played");

    pauseOther(id);

    // attention on chrome with a cached video hasAudio might be set even though the video has a audiotrack.
    // only after the video has been successfully played hasAudio might be set to true
    if (!instance.hasAudio)
      $(instance.elements.container).addClass("plyr-has-no-volume-control");
  });

  plyrs[id].plyr.on("ended", (event) => {
    const instance = event.detail.plyr;
    $(instance.elements.container).addClass("plyr-has-ended");
  });

  plyrs[id].plyr.on("controlsshown", () => {
    $("html").removeClass("hide-ui");
  });

  plyrs[id].plyr.on("controlshidden", () => {
    $("html").addClass("hide-ui");
  });

  plyrs[id].plyr.on("error", (err) => {
    console.error(`PlyrController`, err);
  });
};

export const onResize = () => {
  Object.keys(plyrs).forEach((pID) => {
    const container = $(`#${pID}`);
    if (container.exists()) {
      const newSrc = fitSrc(
        plyrs[pID].sources,
        plyrs[pID].activeKey,
        container.width(),
        container.height()
      );

      if (newSrc.url) {
        const currSrcSize = parseInt(
          plyrs[pID].activeKey.replace(/[^0-9.]/g, ""),
          10
        );
        const newSrcSize = parseInt(newSrc.key.replace(/[^0-9.]/g, ""), 10);

        if (newSrcSize > currSrcSize) {
          plyrs[pID].activeKey = newSrc.key;

          const isPlaying = plyrs[pID].plyr.playing;
          const isPaused = plyrs[pID].plyr.paused && !plyrs[pID].plyr.stopped;
          const isStopped = plyrs[pID].plyr.stopped;
          const { currentTime } = plyrs[pID];

          plyrs[pID].plyr.source = {
            type: "video",
            sources: [
              {
                src: newSrc.url,
                type: "video/mp4",
              },
            ],
            poster: getPosterURL(
              plyrs[pID].json,
              container.width(),
              container.height()
            ),
          };

          if (isPlaying) {
            plyrs[pID].plyr.once("canplay", () => {
              plyrs[pID].currentTime = currentTime;

              const playPromise = plyrs[pID].plyr.play();

              if (playPromise !== undefined) {
                playPromise.catch(() => {
                  // Automatic playback failed.
                  // Show a UI element to let the user manually start playback.
                });
              }
            });
          }

          if (isPaused) {
            plyrs[pID].plyr.once("canplay", () => {
              plyrs[pID].plyr.currentTime = currentTime;
              plyrs[pID].plyr.pause();
            });
          }

          if (isStopped) {
            plyrs[pID].plyr.once("canplay", () => {
              plyrs[pID].plyr.currentTime = currentTime;
              plyrs[pID].plyr.stop();
            });
          }
        }
      }
    }
  });
};

export const pjaxSend = () => {
  unloadAll();
  isInit = false;
};

const initVideoPlayers = () => {
  $(".video-player:not(.initialized)").each((index, element) => {
    const videoPlayer = $(element);
    const id = videoPlayer.attr("id");

    initPlayer(videoPlayer, id);
  });
};

export const initPlayers = () => {
  // initAudioPlayers();
  initVideoPlayers();
};

export const init = () => {
  if (isInit) return;

  isInit = true;

  if (!$(".video-player,.audio-player").exists()) return;

  if (!assets.css.loaded) {
    assets.css.loaded = true;

    if (!$("#plyr-css").exists()) {
      const link = $("<link />");
      link
        .attr({
          id: "plyr-css",
          rel: "stylesheet",
          type: "text/css",
          href: assets.css.url,
        })
        .appendTo("head");
    }
  }

  if (typeof window.Plyr !== "undefined") {
    assets.js.loaded = true;
  }
  if (!assets.js.loaded && !assets.js.loading) {
    assets.js.loading = true;
    getScript(assets.js.url, () => {
      assets.js.loaded = true;
      initPlayers();
    });
  } else if (assets.js.loaded) {
    initPlayers();
  }
};

export const onReady = () => {
  if (isInit) return;

  init();
};

export const onReadyOneTime = (jsUrl, cssUrl, blankVideoUrl) => {
  assets.js.url = jsUrl;
  assets.css.url = cssUrl;
  assets.blankVideo.url = blankVideoUrl;
  onReady();
};
