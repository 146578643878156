/* eslint-disable no-console */
import $ from "cash-dom";
import { getScript } from "../helpers/utils";

const assets = {
  js: {
    url: false,
    loading: false,
    loaded: false,
  },
};

const selectors = {
  container: ".fullscreen-view",
  trigger: ".resource.with-fullscreen,.fullscreen-button",
  button: ".fullscreen-button",
};
const timings = {
  open: 500,
  close: 500,
};

const cropBody = true;

let lastScrollTop = 0;

let button = null;
let container = null;
let openingElement = null;
let timer = 0;
let busy = false;
let isOpen = false;
let swipers = [];

const getValue = (node, key, fallback) =>
  node.getAttribute(key) && !Number.isNaN(node.getAttribute(key))
    ? parseInt(node.getAttribute(key), 10)
    : fallback;

const trapFocusKeyDownEventHandler = (e) => {
  const focusableEls = container.find(
    "a[href]:not([disabled]), button:not([disabled]), div[role]"
  );
  const firstFocusableEl = focusableEls[0];
  const lastFocusableEl = focusableEls[focusableEls.length - 1];

  const isTabPressed = e.key === "Tab" || e.keyCode === 9;

  if (!isTabPressed) {
    return;
  }

  if (e.shiftKey) {
    if (document.activeElement === firstFocusableEl) {
      lastFocusableEl.focus();
      e.preventDefault();
    }
  } else if (document.activeElement === lastFocusableEl) {
    firstFocusableEl.focus();
    e.preventDefault();
  }
};

export const lock = () => {
  busy = true;
};

export const unlock = () => {
  busy = false;

  if (timer > 0) {
    clearTimeout(timer);
    timer = 0;
  }
};

export const close = () => {
  if (busy) return;

  if (!button) return;

  if (!isOpen && !container.hasClass("open")) return;

  if (timer > 0) {
    clearTimeout(timer);
    timer = 0;
  }

  lock();

  window.app.html.attr("data-fullscreen", "closed");
  window.app.html.attr("data-overlay", "closed");
  const body = $("body");
  if (cropBody && body.hasClass("crop")) {
    if (window.app.scrollbarWidth > 0) {
      body.css("padding-right", "");
      $(selectors.header).css("width", "");
    }
    body.removeClass("crop");

    window.scrollTo({
      left: 0,
      top: lastScrollTop,
    });
  }

  container.removeClass("open").addClass("close");
  container.off("keydown");
  button.off("click");

  const block = $(`#${container.data("block-id")}`);
  if (block) {
    block
      .find("[aria-expanded]")
      .attr("aria-expanded", false)
      .attr("aria-label", "Open fullscreen and download view");
  }

  if (openingElement && $('body').hasClass('tabbed')) {
    openingElement.get(0).focus();
  }
  timer = setTimeout(() => {
    unlock();
    if (container) {
      container.removeClass("close");
    }
    isOpen = false;

    Object.keys(swipers).forEach((pID) => {
      swipers[pID].destroy(true, true);
    });

    button = null;
    container = null;
    openingElement = null;
    swipers = [];
  }, timings.close);
};

export const open = (id, initialSlide = 0) => {
  container = $(`#${id}`);
  button = container.find(".toggle");

  if (busy) return;

  if (!button) return;

  if (timer > 0) {
    clearTimeout(timer);
    timer = 0;
  }

  button.on("click", close);

  if (container.hasClass("open")) {
    close();
  } else {
    lock();

    if (cropBody) {
      lastScrollTop = window.pageYOffset;
      if (window.app.scrollbarWidth > 0) {
        $("body").css("padding-right", window.app.scrollbarWidth);
        $(selectors.header).css(
          "width",
          window.app.wW - window.app.scrollbarWidth
        );
      }
      $("body").addClass("crop");
    }

    container.addClass("open");

    window.app.html.attr("data-fullscreen", "open");
    window.app.html.attr("data-overlay", "open");
    isOpen = true;
    button.removeAttr("hidden");

    if ($('body').hasClass('tabbed')) {
      button.get(0).focus();
    }

    container.off("keydown").on("keydown", trapFocusKeyDownEventHandler);

    const block = $(`#${container.data("block-id")}`);
    if (block) {
      block
        .find("[aria-expanded]")
        .attr("aria-expanded", true)
        .attr("aria-label", "Close fullscreen and download view");
    }

    container
      .find(".swiper")
      .each((index, element) => {
        element.classList.add(`fs-swiper-${index}`);

        const swiper = new window.Swiper(`.fs-swiper-${index}`, {
          effect: element.getAttribute("data-effect") ?? "fade",
          speed: getValue(element, "data-speed", 500),
          loop: element.getAttribute("data-loop")
            ? element.getAttribute("data-loop") !== "false"
            : true,
          autoplay:
            element.getAttribute("data-autoplay") &&
            element.getAttribute("data-autoplay") !== "false"
              ? {
                  delay: getValue(element, "data-delay", 4000),
                }
              : false,

          initialSlide,
          edgeSwipeDetection: true,
          edgeSwipeThreshold: 40,
          grabCursor: true,
          slidesPerView: 1,
          navigation: {
            nextEl: `.fs-swiper-${index} .swiper-button-next`,
            prevEl: `.fs-swiper-${index} .swiper-button-prev`,
          },
          mousewheel: {
            forceToAxis: true,
            sensitivity: 1,
            thresholdDelta: 60,
          },
          on: {
            afterInit: () => {
              container
                .find(".no-tab")
                .attr("tabindex", "-1")
                .attr("aria-hidden", "true");
            },
          },
        });

        swipers.push(swiper);
      })
      .on("pointerenter", () => {
        container.addClass("pointer-over");
      })
      .on("pointerleave", () => {
        container.removeClass("pointer-over");
      });

    timer = setTimeout(() => {
      unlock();
    }, timings.open);
  }
};

const init = () => {
  $(selectors.trigger)
    .off("click")
    .on("click", (event) => {
      event.preventDefault();

      let target = $(event.target);

      if (
        !target.hasClass("with-fullscreen") &&
        !target.hasClass("fullscreen-button")
      ) {
        target = target.parents(".resource.with-fullscreen,.fullscreen-button");
      }

      if (target.exists() && target.data("fs-id")) {
        openingElement = target;
        open(target.data("fs-id"), target.data("fs-index") ?? 0);
      }
    });
};

export const onReady = () => {
  if (!$(".with-fullscreen").exists()) return;

  if (typeof window.Swiper !== "undefined") {
    assets.js.loaded = true;
  }

  if (!assets.js.loaded && !assets.js.loading) {
    assets.js.loading = true;
    getScript(assets.js.url, () => {
      assets.js.loaded = true;
      init();
    });
  } else if (assets.js.loaded) {
    init();
  }
};

export const onReadyOneTime = (jsUrl) => {
  assets.js.url = jsUrl;
  onReady();
};

export const pjaxSend = () => {
  close();

  $(selectors.trigger).off("click");

  if (cropBody) {
    const body = $("body");
    if (window.app.scrollbarWidth > 0) {
      body.css("padding-right", "");
      $(selectors.header).css("width", "");
    }
    body.removeClass("crop");
  }

  if (button) button.off("click");
  if (container) container.off("keydown");

  Object.keys(swipers).forEach((pID) => {
    swipers[pID].destroy(true, true);
  });

  swipers = [];
  button = null;
  container = null;
  openingElement = null;
};
